import Vue from 'vue'
import VueGtag from 'vue-gtag'
import config from '~/config'

export default (context, inject) => {
  Vue.use(
    VueGtag,
    {
      config: {
        id: config.gaToken[0]
      },
      params: {
        send_page_view: true
      },
      includes: config.gaToken.slice(1).map((token) => ({
        id: token,
        send_page_view: true
      })),
      pageTrackerScreenviewEnabled: true
    },
    context.app.router
  )

  context.$ga = Vue.$gtag
  inject('ga', Vue.$gtag)
}
