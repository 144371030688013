const title = 'Ennoshita | 結婚式の2次会を盛り上げるリアルタイム参加型クイズ'
const description =
  '結婚式2次会で幹事を任されたあなた！用意するコンテンツにお困りではありませんか？Ennoshitaなら全員が参加して楽しめるリアルタイムクイズが簡単に作れます！オンライン参加やランキング集計など便利な機能がたくさん'
const configs = {
  development: {
    url: 'http://0.0.0.0:3000',
    adminOrigin: 'http://0.0.0.0:4000',
    joinOrigin: 'http://0.0.0.0:4001',
    gaToken: ['G-E29L0300SC', 'G-5NXY5MPJ8W'],
    contentfulSpaceId: '79j1v2v8az3c',
    contentfulAccessToken: 'cXxl_n1Y6RO_p5eUZFJOiVQ1eJnIQh3wGgDyq6R4h_U',
    contentfulEnvironment: 'master'
  },
  staging: {
    title,
    description,
    robots: 'noindex',
    ogImage: 'https://d29975eff3n10h.cloudfront.net/images/ogp.png',
    url: 'https://d29975eff3n10h.cloudfront.net',
    adminOrigin: 'https://d2trrv6ukj9r5t.cloudfront.net',
    joinOrigin: 'https://d3qkbro943cp2t.cloudfront.net',
    gaToken: ['G-E29L0300SC', 'G-5NXY5MPJ8W'],
    contentfulSpaceId: '79j1v2v8az3c',
    contentfulAccessToken: 'cXxl_n1Y6RO_p5eUZFJOiVQ1eJnIQh3wGgDyq6R4h_U',
    contentfulEnvironment: 'master'
  },
  production: {
    title,
    description,
    robots: 'index, follow',
    ogImage: 'https://ennoshita.app/images/ogp.png',
    url: 'https://ennoshita.app',
    adminOrigin: 'https://projects.ennoshita.app',
    joinOrigin: 'https://join.ennoshita.app',
    gaToken: ['G-GYE1HTVRB7', 'G-HE9732KJTQ'],
    contentfulSpaceId: '79j1v2v8az3c',
    contentfulAccessToken: 'cXxl_n1Y6RO_p5eUZFJOiVQ1eJnIQh3wGgDyq6R4h_U',
    contentfulEnvironment: 'master'
  }
}

export default configs[process.env.STAGE || 'development']
